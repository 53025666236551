<template>
	<v-form ref="form">
		<v-card-text>
			<template v-if="!isLoginInProgress && UsersList.length === 0">
				<v-row dense>
					<v-col cols="12" v-if="useUserAccount">
						<p>{{$t('Auth.BankIDPersNrFromUserAccountText')}}</p>
					</v-col>
					<v-col cols="12" md="6">
						<v-btn width="100%" color="primary" :disabled="isLoginInProgress" @click="onStartLogin(BANKID_MOBILE)"><v-icon left>mdi-cellphone</v-icon> {{$t('Auth.BankIDMobile')}}</v-btn>
					</v-col>
					<v-col cols="12" md="6">
						<v-btn width="100%" color="secondary" :disabled="isLoginInProgress" @click="onStartLogin(BANKID_ON_FILE)"><v-icon left>mdi-laptop</v-icon> {{$t('Auth.BankIDOnFile')}}</v-btn>
					</v-col>
				</v-row>
			</template>

			<template v-if="UsersList.length === 0">
				<v-row dense justify="center" v-if="isLoginInProgress">
					<v-col cols="12" class="text-center">
						<v-progress-circular v-if="!statusText" color="accent" :size="50" indeterminate class="mr-2"/>
						<p v-if="statusText">{{statusText}}</p>
					</v-col>

					<v-fade-transition hide-on-leave>
						<v-col v-if="QRCodeDataURL">
							<v-card flat height="220">
								<v-card-text class="pa-0 ma-0 text-center">
									<img v-if="QRCodeDataURL" :src="QRCodeDataURL" style="margin: 0 auto; border: 1px solid gray;" height="220" width="220"/>
								</v-card-text>
							</v-card>
						</v-col>
					</v-fade-transition>

					<v-col cols="12" v-if="Type === 1 && $vuetify.breakpoint.smAndDown && AutoStartToken && QRCodeDataURL" class="text-center">
						<v-btn depressed small color="primary" class="mr-2" @click="tryStartBankIDAppOnThisDevice">Starta BankID på den här enheten</v-btn>
					</v-col>
				</v-row>
			</template>

			<template v-else>
				<v-list>
					<v-subheader class="subtitle-1">{{$t('Auth.SelectUserAccount')}}</v-subheader>
					<v-list-item v-for="(item, index) in UsersList" :key="index" @click="onSelectUser(item)">
						<v-list-item-content>
							<v-list-item-title>{{item.Login}}</v-list-item-title>
							<v-list-item-subtitle>{{$formatter.getRollText(item.Roll)}}</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<Tooltip :text="$t('Auth.SelectUserAccount')">
								<v-btn icon @click="onSelectUser(item)"><v-icon>mdi-login</v-icon></v-btn>
							</Tooltip>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</template>
		</v-card-text>
		<v-card-actions>
			<v-btn v-if="showCancel && (isLoginInProgress || UsersList.length > 0)" style="width: 100%;" color="secondary" @click="onCancel">{{$t('Cancel')}}</v-btn>
		</v-card-actions>
	</v-form>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import isMobile               from 'is-mobile';
import LocalStorage           from '../../../lib/LocalStorage.js';
import Tooltip                from '../../Tooltip.vue';

export default {
	name: 'BankIDLogin',

	props: {
		buttonText: {
			type:    String,
			default: '',
		},

		showCancel: {
			type:    Boolean,
			default: true,
		},

		useUserAccount: {
			type:    Boolean,
			default: false,
		},
	},

	data: () => ({
		isLoginInProgress:     false,
		statusText:            '',
		UsersList:             [],
		QRCodeDataURL:         '',
		currentOrderRef:       null,
		Type:                  null,
		AutoStartToken:        null,
		PersNr:                undefined,
		BANKID_ON_FILE:        0,
		BANKID_MOBILE:         1,
		BANKID_ON_THIS_DEVICE: 2,
	}),

	methods: {
		...mapActions(['setAuthenticated']),

		onSelectUser(user) {
			this.$tryCatch({
				task: async () => {
					const {UserAccount, UserAppSettings, FtgInfo, Auth, HasLicensOption, SalaryYears, HasPlus, HasWebLon, HasWebLonPlus} = await this.$http.post('/bankidauth/selectuser', {
						BankIDToken: user.BankIDToken,
						Login:       user.Login,
						Type:        this.Type,
					});

					if(UserAppSettings && ['dark', 'white'].includes(UserAppSettings.Theme)) {
						this.$vuetify.theme.dark = UserAppSettings.Theme === 'dark';
					}

					this.setAuthenticated({UserAccount, UserAppSettings, FtgInfo, Auth, HasLicensOption, SalaryYears, HasPlus, HasWebLon, HasWebLonPlus});
					this.$emit('authenticated')
				},
			})
		},

		onCancel() {
			clearTimeout(this.collectTimer);
			this.UsersList = [];
			this.statusText = '';
			this.isLoginInProgress = false;
			this.QRCodeDataURL = '';

			const currentOrderRef = this.currentOrderRef;

			this.currentOrderRef = null;

			if(currentOrderRef) {
				this.$tryCatch({
					task: async () => {
						await this.$http.delete(`/bankidauth/cancel/${encodeURIComponent(currentOrderRef)}`)
					},
				});
			}
		},

		onStartLogin(Type) {
			LocalStorage.set('LoginMethod', 0);
			this.currentOrderRef = null;

			this.Type = Type;

			this.$tryCatch({
				task: async () => {
					this.isLoginInProgress = true;

					// if((this.type === 1 || this.useUserAccount) && !this.PersNr) {
					// 	this.$msgError(this.$t(this.useUserAccount ? 'Auth.RequiresBankID.PersonalIdentityNumberMissing' : 'Auth.PersonalIdentityNumberMissing'));
					// 	return;
					// }

					const {OrderRef, AutoStartToken} = await this.$http.post('/bankidauth', {
						Type:   this.Type,
						PersNr: this.useUserAccount ? this.UserAccount.PersNr : undefined,
					});

					this.AutoStartToken = AutoStartToken;

					this.currentOrderRef = OrderRef;

					this.collect(OrderRef);

					if(Type === this.BANKID_ON_FILE) {
						this.tryStartBankIDAppOnFile();
					} else if(isMobile()) {
						this.tryStartBankIDAppOnMobile();
					}
				},
				error: () => {
					this.isLoginInProgress = false;
				},
			});
		},

		tryStartBankIDApp(Type) {
			const params = [
				`autostarttoken=${this.AutoStartToken}`,
				'redirect=null',
			];

			if(
				Type === this.BANKID_ON_FILE ||
				Type === this.BANKID_ON_THIS_DEVICE
			) {
				window.location = `bankid:///?${params.join('&')}`;
			} else if(Type === this.BANKID_MOBILE) {
				window.location = `https://app.bankid.com/?${params.join('&')}`;
			}
		},

		tryStartBankIDAppOnFile() {
			this.tryStartBankIDApp(this.BANKID_ON_FILE)
		},

		tryStartBankIDAppOnMobile() {
			this.tryStartBankIDApp(this.BANKID_MOBILE)
		},

		tryStartBankIDAppOnThisDevice() {
			this.tryStartBankIDApp(this.BANKID_ON_THIS_DEVICE)
		},

		collect(orderRef) {
			const collect           = async () => {
				if(this.collectAttempts > 60) {
					this.onCancel();

					return;
				}

				try {
					const {
						UsersList,
						Completed,
						Cancelled,
						Message,
						QRCodeDataURL,
						PendingStart,
					} = await this.$http.get(`/bankidauth/collect/${orderRef}`);

					if(Cancelled || Completed) {
						if(Cancelled) {
							setTimeout(() => {
								this.statusText    = Message;
							}, 7000);
							setTimeout(() => {
								this.isLoginInProgress = false;
							}, 10000);
						} else {
							this.isLoginInProgress = false;
						}
					}

					if(PendingStart && QRCodeDataURL) {
						this.QRCodeDataURL = QRCodeDataURL;
						this.statusText    = 'Skanna QR-koden i BankID-appen';
					} else {
						this.QRCodeDataURL = '';
						if(!Cancelled) {
							this.statusText    = Message;
						}
					}

					if(Completed) {
						if(UsersList.length === 0) {
							this.$msgError(this.$t('Auth.NoAccountsExistForPersonalIdentityNumber'));
							
							return;
						}

						if(this.useUserAccount) {
							this.onSelectUser(UsersList.find((item) => item.Login === this.UserAccount.Login));
						} else if(UsersList.length === 1 && UsersList[0].Roll === 'PER') {
							this.onSelectUser(UsersList[0]);
						} else {
							this.UsersList = UsersList;
						}
					} else if(!Cancelled) {
						this.collectTimer = setTimeout(collect, 1500);
					}
				} catch{
					this.isLoginInProgress = false;
					clearTimeout(this.collectTimer);
				} finally {
					this.collectAttempts = this.collectAttempts + 1;
				}
			};

			if(this.collectTimer) {
				clearTimeout(this.collectTimer);
			}

			this.collectAttempts = 0;

			collect();
		},
	},

	computed: {
		...mapState(['UserAccount']),
	},

	components: {
		Tooltip,
	},
}
</script>

<style scoped>

</style>
