<template>
	<Dialog
					v-bind="$attrs"
					v-model="dialog"
					:width="700"
					persistent
					ref="dialog"
					:use-card-padding="false"
					no-padding
					@click:outside="onClickOutside"
					@show="onShow"
	>
		<v-app-bar dark color="primary" dense>
			<v-toolbar-title>
				<v-img
						:src="require('@/assets/img/cronaportal-side-white.png')"
						:lazy-src="require('@/assets/img/cronaportal-side-white.png')"
						contain
						width="175"
				/>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn small text class="no-hover pa-0 mr-2" :ripple="false">{{UserAccount.Namn}}</v-btn>
			<Tooltip :text="$t('Logout')">
				<v-btn icon @click="onLogout" small><v-icon>mdi-logout</v-icon></v-btn>
			</Tooltip>
		</v-app-bar>
		<v-tabs
						v-if="!isLoading"
						v-model="selectedTab"
						:color="$vuetify.theme.dark ? 'accent' : 'primary'"
						slider-color="accent"
						grow
						card
		>
			<v-tab
							:key="0"
							ripple
							:disabled="companyList.length === 0"
			>
				{{$t('SelectCompany')}}
			</v-tab>
			<v-tab-item
							:key="0"
			>
				<v-divider></v-divider>
				<v-card flat>

					<v-card-text>
						<SimpleTextInput
							v-if="!isLoading && companyList.length >= 10"
							autofocus
							name="SearchTermCompanyList"
							ref="SearchTermCompanyList"
							v-model="searchTermCompanyList"
							append-icon="mdi-magnify"
							placeholder="Sök namn, orgnr, id.."
							hide-details
							clearable
							class="mb-2"
						/>

						<v-sheet max-height="700" style="overflow: auto;" :outlined="!isLoading">
							<v-list
								v-if="!isLoading && searchTermCompanyList && (companyListOthers.length + companyListFavourites.length) === 0"
							>
								<v-subheader>{{$t('NoDataTextGeneric')}}..</v-subheader>
							</v-list>

							<SelectCompanyList
								:key="0"
								v-if="!isLoading && companyListFavourites.length > 0"
								:items="companyListFavourites"
								:title="$t('Favoriter')"
								@click="selectCompany"
							/>

							<SelectCompanyList
								:key="1"
								v-if="!isLoading && companyListOthers.length > 0"
								:items="companyListOthers"
								:title="companyListFavourites.length > 0 ? $t('Övriga') : ''"
								@click="selectCompany"
							/>
						</v-sheet>
					</v-card-text>
				</v-card>
			</v-tab-item>
		</v-tabs>

		<v-card flat v-if="isLoading">
			<v-card-text>
				<Loading :visible="true"/>
			</v-card-text>
		</v-card>
	</Dialog>
</template>

<script>

import _ from 'lodash';

import {mapState, mapActions} from 'vuex';
import moment                 from 'moment';
import LocalStorage           from '../lib/LocalStorage.js';
import Loading                from './Loading.vue';
import InputField             from './form/InputField.vue';

import SelectCompanyList from './SelectCompanyList.vue';


import Dialog          from './Dialog.vue';
import Tooltip         from './Tooltip.vue';
import InfiniteSelect  from './InfiniteSelect.vue';
import SimpleTextInput from './SimpleTextInput.vue';

export default {
	name: 'SelectCompanyDialog',

	props: {
		showOnMounted: {
			type:    Boolean,
			default: true,
		},
	},

	data: () => ({
		tabOrder: [
			'FtgId',
			'Namn',
			'Year',
			'LoneartsTabell',
			'SaveBtn',
		],
		dialog:                true,
		isLoading:             true,
		isSavingNewCompany:    false,
		companyList:           [],
		selectedTab:           0,
		Year:                  moment().year(),
		searchTermCompanyList: '',
	}),

	computed: {
		...mapState(['UserAccount', 'FtgInfo']),

		companyListFavourites() {
			return this.filterAgainstSearchTermCompany(_.sortBy(_.filter(this.companyList, {isFavourite: true, Archived: false}), ['Namn']));
		},

		companyListOthers() {
			return this.filterAgainstSearchTermCompany(_.sortBy(_.filter(this.companyList, (item) => !item.isFavourite && !item.Archived), 'Namn'));
		},

		YearList() {
			const {VUE_APP_FIRST_YEAR} = process.env;

			let currentYear = moment().year();

			const years = [];

			while(currentYear >= _.toNumber(VUE_APP_FIRST_YEAR)) {
				years.push({
					value: currentYear,
					text:  currentYear,
				});
				currentYear--;
			}

			return years;
		},
	},

	mounted() {
		if(this.showOnMounted) {
			this.show();
		}
	},

	methods: {
		...mapActions(['setAuthenticated', 'confirmDialog', 'logout']),

		filterAgainstSearchTermCompany(items) {
			this.searchTermCompanyList = this.searchTermCompanyList || '';

			if(!this.searchTermCompanyList.trim()) {
				return items;
			}

			const searchTerm = this.searchTermCompanyList.trim().toLowerCase();

			return items
				.filter(({Namn, Orgnr, FtgId}) =>
					Namn.toLowerCase().includes(searchTerm) ||
								Orgnr.toLowerCase().includes(searchTerm) ||
								FtgId.toLowerCase().includes(searchTerm)
				);
		},

		onLogout() {
			this.logout();
		},

		show() {
			this.$refs.dialog.show();
		},

		hide() {
			this.$refs.dialog.hide();
		},

		onShow() {
			if(
				['PER', 'AVD', 'LSS', 'BRU'].includes(this.UserAccount.Roll) ||
				(this.UserAccount.Roll === 'FTG' && this.UserAccount.FtgIdAccessList.filter((value) => value !== this.UserAccount.FtgId).length === 0)
			) {
				this.hide();
				this.selectCompany({FtgId: this.UserAccount.FtgId});
			} else {
				this.loadCompanyList();
			}
		},

		onClickOutside() {
			if(this.FtgInfo.FtgId) {
				this.$refs.dialog.hide();
			}
		},

		loadCompanyList() {
			return this.$tryCatch({
				task: async () => {
					this.isLoading = true;

					const favourites = LocalStorage.get('FavouriteCompanies') || [];

					const companyList = await this.$http.get('/companyList');

					for(const company of companyList) {
						const {FtgId} = company;

						company.isFavourite = favourites.includes(FtgId);
					}

					this.companyList = companyList;

					this.selectedTab = this.companyList.length === 0 ? 1 : 0;
				},

				finally: () => {
					this.isLoading = false;
				},
			});
		},

		selectCompany(item) {
			//Står redan på detta bolag, behöver inte ladda om något.
			if(this.FtgInfo && this.FtgInfo.FtgId === item.FtgId) {
				this.$emit('cancel');

				return;
			}

			return this.$tryCatch({
				task: async () => {
					this.isLoading = true;

					const FtgInfo = await this.$http.get(`/companyInfo/${item.FtgId}`);

					if(!FtgInfo) {
						return;
					}

					const SalaryYears = await this.$http.get('/salaryyears', {
						headers: {
							'dv-selected-company': item.FtgId,
						},
					});

					const UserAppSettings = await this.$http.get('/userAppSettings');

					const emitChange = () => {
						this.setAuthenticated({UserAccount: this.UserAccount, UserAppSettings, FtgInfo, SalaryYears});
						this.$emit('change', FtgInfo);
					};

					if(Object.keys(this.$route.params).length > 0) {
						this.$router.push({name: 'Dashboard'}, emitChange);
					} else {
						emitChange();
					}
				},

				finally: () => {
					this.isLoading = false;
				},
			});
		},
	},

	components: {
		SimpleTextInput,
		InfiniteSelect,
		Tooltip,
		Dialog,
		Loading,
		InputField,
		SelectCompanyList,
	},
}
</script>

<style scoped>
	::v-deep(.no-hover) {
		cursor: auto !important;
	}
	::v-deep(.no-hover:hover::before) {
		opacity: 0 !important;
	}
</style>
