<template>
	<div>
		<v-system-bar
			app
			dark
			color="error"
			class="text-center white--text"
			v-if="NetworkOffline"
		>
			<v-spacer/>
			<v-icon left>mdi-network-off</v-icon> Störningar har upptäckts i din internetanslutning <v-btn class="ml-2" icon loading x-small><v-icon x-small>mdi-loading</v-icon></v-btn>
			<v-spacer/>
		</v-system-bar>

		<v-app-bar
						app
						color="primary"
						dark
						fixed
						clipped-left
						flat
						dense
						:style="computedAppStyle"
		>
			<v-toolbar-title style="width: 300px">
				<v-row dense>
					<v-col cols="1">
						<v-app-bar-nav-icon @click.stop="$eventBus.$emit('toggleNavigation')" :aria-label="$t('MainMenu')"></v-app-bar-nav-icon>
					</v-col>
					<v-col cols="11">
						<v-img
							:src="require('@/assets/img/cronaportal-side-white.png')"
							:lazy-src="require('@/assets/img/cronaportal-side-white.png')"
							width="155"
							height="45"
							contain
							class="ml-4"
						/>
					</v-col>
				</v-row>
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<Tooltip :text="$t('Messages')" v-if="HasPlus && PortalInfo.EnableMeddelanden">
				<v-badge left :color="UnreadMessagesCount > 0 ? 'red' : 'transparent'" class="align-self-center" :offset-y="15">
					<template v-slot:badge>
						<span v-if="UnreadMessagesCount > 0">{{UnreadMessagesCount}}</span>
					</template>
					<v-btn to="/messages" icon small class="pr-0 mr-2 ma-0" :aria-label="$t('Messages')">
						<v-icon>mdi-inbox</v-icon>
					</v-btn>
				</v-badge>
			</Tooltip>

			<v-menu
							v-model="userMenuOpen"
							offset-y
							:max-height="300"
							open-on-hover
							open-on-click
			>
				<template v-slot:activator="{on}">
					<v-btn v-on="on" small text class="hidden-xs-only pa-0 pr-1 pl-1" @click="userMenuOpen = !userMenuOpen">
						<Tooltip :text="$t('HappyBirthday')">
							<v-icon v-if="$isBirthday(UserAccount.PersNr)" color="red" small>mdi-balloon</v-icon>
						</Tooltip>
						{{UserAccount.Namn || UserAccount.Login}} <v-icon>{{userMenuOpen ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon></v-btn>
					<v-btn v-on="on" small icon class="hidden-sm-and-up"><v-icon>mdi-account</v-icon></v-btn>
				</template>

				<v-card class="pb-0 pt-0">
					<v-list dense class="pt-0 pb-0">
						<v-list-item>

							<v-list-item-content>
								<v-list-item-title>
									<Tooltip :text="$t('HappyBirthday')">
										<v-icon v-if="$isBirthday(UserAccount.PersNr)" color="red" small>mdi-balloon</v-icon>
									</Tooltip>
									{{UserAccount.Namn || UserAccount.Login}}</v-list-item-title>
								<v-list-item-subtitle>{{$formatter.getRollText(UserAccount.Roll)}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item to="/userAppSettings">
							<v-list-item-action>
								<v-icon>mdi-cog-outline</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{$t('Settings.Title')}}</v-list-item-title>
								<v-list-item-subtitle>{{$t('Settings.MyPreferences')}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="onClickChangePassword">
							<v-list-item-action>
								<v-icon>mdi-lock-reset</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{$t('ChangePassword')}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="toggleTheme">
							<v-list-item-action>
								<v-icon :color="$vuetify.theme.dark ? 'white' : 'darkgrey'">mdi-theme-light-dark</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{$t('Settings.Theme')}}</v-list-item-title>
								<v-list-item-subtitle>{{$t('Settings.ThemeSwap', {theme: $vuetify.theme.dark ? $t('ljust') : $t('mörkt')})}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-divider></v-divider>


					<v-card-actions class="pa-0 ma-0">
						<v-btn style="width: 100%;" text @click="userMenuOpen = false">{{$t('Close')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-menu>

			<Tooltip :text="$t('Logout')" direction="right">
				<v-btn class="ml-2" icon @click="onLogout" small :aria-label="$t('Logout')"><v-icon>mdi-logout</v-icon></v-btn>
			</Tooltip>

		</v-app-bar>

		<SelectCompanyDialog ref="selectCompanyDialog" @cancel="$refs.selectCompanyDialog.hide()" @change="$refs.selectCompanyDialog.hide()" :showOnMounted="false"/>
		<ChangePasswordDialog ref="changePasswordDialog"/>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import moment                 from 'moment';
import LocalStorage           from '../lib/LocalStorage.js';
import SelectCompanyDialog    from './SelectCompanyDialog.vue';
import Tooltip                from './Tooltip.vue';
import ChangePasswordDialog   from './ChangePasswordDialog.vue';
import Title                  from './typography/Title.vue';

moment.locale('sv');

export default {
	name: 'AppToolbar',

	data: () => ({
		selectCompanyMenuOpen: false,
		dialog:                false,
		userMenuOpen:          false,
	}),

	mounted() {
		window.addEventListener('online', () => {
			this.toggleNetworkOffline(false);
		}, false);

		window.addEventListener('offline', () => {
			this.toggleNetworkOffline(true);
		}, false);
	},

	computed: {
		...mapState(['FtgInfo', 'UserAccount', 'SalaryYears', 'SalaryYear', 'Auth', 'PortalInfo', 'UnreadMessagesCount', 'UserAppSettings', 'NetworkOffline']),

		canChangeCompany() {
			return this.isAdmin || (this.UserAccount.Roll === 'FTG' && this.UserAccount.FtgIdAccessList.length > 1);
		},

		computedAppStyle() {
			let borderBottom = '1px solid rgba(0, 0, 0, 0.12)';

			if(this.$vuetify.theme.dark) {
				borderBottom = '1px solid rgba(255, 255, 255, 0.24)';
			}

			return {
				'border-bottom': borderBottom,
			};
		},
	},

	watch: {},
		
	methods: {
		...mapActions(['logout', 'setSalaryYear', 'confirmDialog', 'toggleNetworkOffline']),

		onShowSelectCompanyDialog() {
			if(!this.canChangeCompany) {
				return;
			}
			this.$refs.selectCompanyDialog.show();
		},

		onClickChangePassword() {
			this.$refs.changePasswordDialog.show();
		},

		toggleTheme() {
			const newValue = !this.$vuetify.theme.dark;

			LocalStorage.setDarkModePreference(newValue);

			this.$vuetify.theme.dark = newValue;

			Object.assign(this.UserAppSettings, {
				Theme: this.$vuetify.theme.dark ? 'dark' : 'white',
			});

			this.$tryCatch({
				task: async () => {
					await this.$http.put('/userAppSettings', this.UserAppSettings);
				},
			});
		},

		printTime(value) {
			return moment(value).fromNow();
			// return moment(value).format('ddd DD MMM HH:mm');
		},

		onLogout() {
			this.userMenuOpen = false;

			this.logout();
		},
	},

	components: {
		Title,
		ChangePasswordDialog,
		Tooltip,
		SelectCompanyDialog,
	},
}
</script>

<style scoped>
	::v-deep(.v-toolbar__content) {
		padding-left: 5px;
		padding-right: 5px;
	}

	::v-deep(.no-button-hover) {
		cursor: initial;
	}

	::v-deep(.no-button-hover:hover::before, .no-button-hover:focus::before) {
		opacity: 0;
	}
</style>
